exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auditoria-tsx": () => import("./../../../src/pages/auditoria.tsx" /* webpackChunkName: "component---src-pages-auditoria-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-busqueda-tsx": () => import("./../../../src/pages/busqueda.tsx" /* webpackChunkName: "component---src-pages-busqueda-tsx" */),
  "component---src-pages-categorias-tsx": () => import("./../../../src/pages/categorias.tsx" /* webpackChunkName: "component---src-pages-categorias-tsx" */),
  "component---src-pages-contactenos-tsx": () => import("./../../../src/pages/contactenos.tsx" /* webpackChunkName: "component---src-pages-contactenos-tsx" */),
  "component---src-pages-especialistas-tsx": () => import("./../../../src/pages/especialistas.tsx" /* webpackChunkName: "component---src-pages-especialistas-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industrias-tsx": () => import("./../../../src/pages/industrias.tsx" /* webpackChunkName: "component---src-pages-industrias-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-politica-tsx": () => import("./../../../src/pages/politica.tsx" /* webpackChunkName: "component---src-pages-politica-tsx" */),
  "component---src-pages-servicios-tsx": () => import("./../../../src/pages/servicios.tsx" /* webpackChunkName: "component---src-pages-servicios-tsx" */),
  "component---src-templates-categoria-tsx": () => import("./../../../src/templates/categoria.tsx" /* webpackChunkName: "component---src-templates-categoria-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-servicio-tsx": () => import("./../../../src/templates/servicio.tsx" /* webpackChunkName: "component---src-templates-servicio-tsx" */)
}

