// import "./src/styles/global.css"
require("./src/styles/global.css");


exports.onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    console.error = function () {};
    return null;
  }

  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  setTimeout(() => {
    if (typeof gtag === 'function') {
      gtag('event', 'page_view', { page_path: pagePath });
    }
  }, 100);
};

/* 
exports.onClientEntry = () => {
  // Cargar el script del píxel de Facebook de manera asincrónica
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '2735675966651735'); // Reemplaza con tu ID de píxel
  fbq('track', 'PageView');
}; */